<template>
    <div class="links">
        <div class="links-main">
            <div class="word">友情链接 <a href="http://www.zyzl-wx.com/" target="_blank">中仪知联</a></div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "links"
    }
</script>

<style scoped>
    .links{
        width: 100%;
        background-color: #d0e9c6;
        /*color: #fff;*/
    }
    .links-main{
        width: 1000px;
        margin: 0 auto;
    }
    .links-main .word{
        line-height: 40px;
    }
    .links-main .word a{
        padding-left: 40px;
        color: #999;
        font-size: 14px;
    }
    .links-main .word a:hover{
        text-decoration: underline;
    }
</style>