<template>
    <div class="contact-us">
        <div class="bg-pic" v-if="obj.connect_image" :style="{backgroundImage:'url(/images/website/' + obj.connect_image + ')'}">
            <div class="txt-box">
                <div class="txt-box-title">联系我们</div>
                <div class="txt-box-time">服务时间：{{obj.Server_time}}</div>
            </div>
        </div>
        <div class="contact-us-main">
            <div class="contact-us-main-left">
                <div class="left-txt">
                    <span>痛点直击</span>
                </div>
                <div class="left-txt2">
                    <p class="left-txt2-p">您是否在工作中有无法解决的烦恼？</p>
                    <p class="left-txt2-p2">您对于工业设备上有什么样的需求与想法？</p>
                    <p class="left-txt2-p3">让我们为你创造价值</p>
                </div>
            </div>
            <div class="contact-us-main-right">
                <div class="contact-us-main-right-main">
                    <div class="right-title">WELCOME TO CONTACT US</div>
                    <div class="right-title2">我们的联系方式</div>
                    <div class="right-tel">
                        <p class="right-tel-p">电话：{{obj.phone}}</p>
                        <p class="right-tel-p2">手机：{{obj.cellphone}}</p>
                    </div>
                    <div class="right-address">地址：{{obj.address}}</div>
                    <div class="right-input">
                        <div class="right-input-list">
                            <input type="text" placeholder="您的姓名" v-model="ruleForm.name" />
                            <input type="text" placeholder="您的电话" v-model="ruleForm.phone" />
                            <textarea class="text-area" placeholder="留言内容" v-model="ruleForm.content"></textarea>
                        </div>
                        <button class="right-button" @click="submit">提交留言</button>
                    </div>
                </div>
            </div>
        </div>
        <message v-if="flag" :msg="msg"  />
        <links />
        <public-footer />
    </div>
</template>

<script>
    import publicFooter from '@/components/common/PublicFooter'
    import message from '@/components/common/message'
    import links from '@/components/common/links'
    export default {
        name: "ContactUs",
        components:{
            publicFooter,
            message,
            links,
        },
        data(){
            return{
                obj:{}, //公司信息

                flag:false, //信息提示框是否显示
                msg:'', //提示信息
                reg:/^(13[0-9]|14[5|7]|15[0|1|2|3|4|5|6|7|8|9]|18[0|1|2|3|5|6|7|8|9])\d{8}$/,
                ruleForm:{
                    name:'',
                    phone:'',
                    content:'',
                },
            }
        },
        created(){
            this.getData();
        },
        methods:{
            //获取公司数据
            async getData(){
                try {
                    const {data} = await this.$axios('/api/companyprofile');
                    if(data.code){
                        this.obj = data.data[0];
                    }
                }catch (e) {
                    //
                }
            },
            //点击提交
            async submit(){
                if(!this.reg.test(this.ruleForm.phone)){
                    this.msg = '您的手机格式不正确';
                    this.flag = true;
                    return;
                }
                try {
                    const {data} = await this.$axios({
                        method:'POST',
                        url:'/api/commemt/add',
                        data:{
                            name:this.ruleForm.name,
                            phone:this.ruleForm.phone,
                            content:this.ruleForm.content,
                        },
                    });
                    if(data.code){
                        this.ruleForm.name = '';
                        this.ruleForm.phone = '';
                        this.ruleForm.content = '';
                        this.msg = data.msg;
                        this.flag = true;
                    }else{
                        this.msg = data.msg;
                        this.flag = true;
                    }
                }catch (e) {
                    this.msg = '网络错误，请稍后重试';
                    this.flag = true;
                }
            },
        },
        watch:{
            flag(val){
                if(val){
                    setTimeout(()=>{
                        this.flag = false;
                    },1000);
                }
            }
        },
    }
</script>

<style scoped>
    .contact-us{
        /*background-color: #f5f5f5;*/
    }
    .contact-us .bg-pic{
        width: 100%;
        height: 650px;
        /*background-image: url("/imgs/contact_banner.jpg");*/
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        position: relative;
    }
    .contact-us .bg-pic .txt-box{
        position: absolute;
        width: 100%;
        color: #ffffff;
        top: 180px;
        text-align: center;
    }
    .contact-us .bg-pic .txt-box .txt-box-title{
        font-size: 50px;
        font-weight: 700;
        line-height: 80px;
        margin-bottom: 20px;
    }
    .contact-us .bg-pic .txt-box .txt-box-time{
        font-size: 28px;
    }

    .contact-us-main{
        width: 1000px;
        margin: 0 auto;
        overflow: hidden;
        padding-top: 30px;
        box-sizing: border-box;
    }
    .contact-us-main .contact-us-main-left{
        float: left;
        position: relative;
        width: 550px;
    }
    .left-txt{
        width: 96px;
    }
    .left-txt span{
        font-size: 82px;
        color: #EDEDED;
        font-weight: bold;
    }
    .left-txt2{
        position: absolute;
        top: 30px;
        left: 77px;
    }
    .left-txt2 .left-txt2-p{
        font-size: 28px;
        color: #222222;
        font-weight: bold;
        margin-bottom: 10px;
    }
    .left-txt2 .left-txt2-p2{
        font-size: 44px;
        color: #555;
        font-weight: bold;
        margin-bottom: 15px;
    }
    .left-txt2 .left-txt2-p3{
        font-size: 40px;
        color: rgb(153, 153, 153);
    }

    .contact-us-main .contact-us-main-right{
        width: 400px;
        float: right;
        padding: 30px;
        box-sizing: border-box;
        transition: .7s ease-in all;
    }

    .contact-us-main-right:hover{
        box-shadow: 0 0 4px 5px #cccccc;
    }
    .right-title{
        font-family: Arial;
        font-size: 24px;
        color: #999999;
    }
    .right-title2{
        font-size: 18px;
        color: #333333;
        font-weight: bold;
        line-height: 40px;
    }
    .right-tel{
        font-size: 12px;
        color: #333333;
        line-height: 30px;
    }
    .right-tel-p{}
    .right-tel-p2{}
    .right-address{
        font-size: 12px;
        color: #333333;
        line-height: 30px;
    }
    .right-input{
        text-align: right;
    }
    .right-input .right-input-list{}
    .right-input .right-input-list input{
        width: 100%;
        border: 2px solid rgb(237,237,237);
        margin-bottom: 10px;
        outline: none;
        line-height: 28px;
        padding: 3px;
        box-sizing: border-box;
        font-size: 12px;
        color: #999999;
    }
    .right-input .right-input-list input:nth-child(1){}
    .right-input .right-input-list input:nth-child(2){}
    .right-input .right-input-list .text-area{
        width: 100%;
        height: 100px;
        outline: none;
        resize: none;
        color: #999999;
        padding: 5px;
        box-sizing: border-box;
        border: 2px solid rgb(237,237,237);
    }
    .right-input .right-button{
        width: 140px;
        height: 40px;
        line-height: 40px;
        color: #fff;
        background-color: #008b58;
        border-radius: 6px;
        border: none;
        cursor: pointer;
    }
</style>