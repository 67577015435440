<template>
    <div class="message">
        <div class="mask"></div>
        <div class="content">{{msg}}</div>
    </div>
</template>

<script>
    export default {
        name: "message",
        props:{
            msg:{
                required:true,
                type:String,
            },
        },
        data(){
            return{
                msg:'商品已下架或不存在!'
            }
        },
        mounted(){
            let oDiv = document.querySelector('.content');
            oDiv.style.marginLeft = '-' + oDiv.clientWidth/2 + 'px';
        },
    }
</script>

<style scoped>
    .message{
        position: fixed;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
    }
    .message .mask{
        width: 100%;
        height: 100%;
        background-color: #000000;
        opacity: .7;
    }
    .message .content{
        /*width: 260px;*/
        height: 80px;
        line-height: 80px;
        text-align: center;
        position: fixed;
        top: 45%;
        /*margin-top: -40px;*/
        left: 50%;
        /*margin-left: -130px;*/
        background-color: #ffffff;
        letter-spacing: 2px;
        padding: 0 20px;
    }
</style>